













































import { Component, Ref } from 'vue-property-decorator'
import BaseModel, { Form } from '../../application/model/BaseModel'
import User from '@/application/model/User'

@Component({})
export default class Login extends BaseModel {
  @Ref('FORM_REF')
  form!: Form

  user = new User()

  constructor () {
    super('login')
  }

  mounted (): void {
    this.user.username = 'test@birkle-it.com'
    this.user.password = 'mock-password'
  }

  validateAndSend (): void {
    if (this.form.validate()) {
      this.$router.push('patients')
    }
  }
}
